import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CreateBusinessParametersPayload,
  FindOneBusinessParametersPayload,
  FindPaginationBusinessParametersPayload,
  UpdateBusinessParametersPayload,
} from '@/domains/business-parameters/ports/payloads';
import { BusinessParametersRepository } from '@/domains/business-parameters/ports/repositories';
import { BusinessParametersEntity } from '@/domains/business-parameters/entities';
import * as GQL from '../../graphql';
import { FindResult } from '@/common';

export class BusinessParametersHttpRepository implements BusinessParametersRepository {
  async findOne(payload: FindOneBusinessParametersPayload) {
    return await HttpClientAdapter.query<BusinessParametersEntity>({
      query: GQL.FIND_ONE_BUSINESS_PARAMETER,
      variables: { criteria: payload },
      dataKey: 'BusinessParameter_findOneBusinessParameter',
    });
  }

  async findPagination(payload: FindPaginationBusinessParametersPayload) {
    const { limit = 20, page = 1, status, name, type } = payload;

    return await HttpClientAdapter.query<FindResult<BusinessParametersEntity>>({
      query: GQL.FIND_PAGINATED_BUSINESS_PARAMETERS,
      variables: { payload: { limit, page, status, name, type } },
      dataKey: 'BusinessParameter_findPaginatedBusinessParameters',
    });
  }

  async create(payload: CreateBusinessParametersPayload) {
    return await HttpClientAdapter.mutate<BusinessParametersEntity>({
      mutation: GQL.CREATE_BUSINESS_PARAMETER,
      dataKey: 'BusinessParameter_createBusinessParameter',
      variables: { payload },
    });
  }

  async update(payload: UpdateBusinessParametersPayload) {
    return await HttpClientAdapter.mutate<BusinessParametersEntity>({
      mutation: GQL.UPDATE_BUSINESS_PARAMETER,
      dataKey: 'BusinessParameter_updateBusinessParameter',
      variables: { payload },
    });
  }
}
