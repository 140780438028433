import { FindResult } from '@/common';
import { BusinessParametersEntity } from '@/domains/business-parameters/entities';
import {
  FindOneBusinessParametersPayload,
  FindPaginationBusinessParametersPayload,
} from '@/domains/business-parameters/ports/payloads';
import { FindBusinessParametersUsecases } from '@/domains/business-parameters/usecases';
import { useRequest } from 'ahooks';
import { BusinessParametersHttpRepository } from '../adapters/repositories';

export function useFindPaginationBusinessParametersViewmodel() {
  const FindUsecases = new FindBusinessParametersUsecases(new BusinessParametersHttpRepository());

  const Req = useRequest(
    async (payload: FindPaginationBusinessParametersPayload) => {
      const result = await FindUsecases.findPagination({ limit: 200, page: 1, ...payload });
      return result;
      // return {
      //   data: [],
      //   total: 0,
      //   limit: 20,
      //   page: 1,
      // } as FindResult<BusinessParametersEntity>;
    },
    { manual: true, retryCount: 2 },
  );

  return Req;
}

export function useFindOneBusinessParametersViewmodel() {
  const FindUsecases = new FindBusinessParametersUsecases(new BusinessParametersHttpRepository());

  const Req = useRequest(
    async (payload: FindOneBusinessParametersPayload) => {
      const result = await FindUsecases.findOne(payload);
      return result;
    },
    { manual: true, retryCount: 2 },
  );

  return Req;
}
