import { cn } from '@/common';
import NotAvailableText from '@/components/helps/NotAvailableText';
import {
  BPStatuses,
  BPTypes,
  BusinessParametersEntity,
  BusinessParameterStatusEnum,
} from '@/domains/business-parameters/entities';
import { FindPaginationBusinessParametersPayload } from '@/domains/business-parameters/ports/payloads';
import { EditOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Button, TableColumnType, Tag, TagProps } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageBusinessParamsContext } from '../context';
import { formatTimeScope } from '../utils';
import InputFilterDropdown from '@/components/InputFilter/InputFilterDropdown';
import CoreSelect from '@/components/Form/SelectAntForm';

type TColumnType = TableColumnType<BusinessParametersEntity> & {
  hidden?: boolean;
};

const TitleFilterIcon = (props: { active?: boolean; extra?: React.ReactNode }) => (
  <div className='relative flex items-center shrink-0 min-h-[30px]'>
    {!!props?.extra && (
      <span className='absolute -left-1 -translate-x-full top-1/2 -translate-y-1/2 font-[400] !text-blue-400'>
        {props?.extra}
      </span>
    )}
    <FilterFilled className={props?.active ? 'text-blue-500' : ''} />
  </div>
);

function useBusinessParametersColumns(): TColumnType[] {
  const { t } = useTranslation();

  const { methodFilterPagination, setPageUrlState, businessUnitList } =
    usePageBusinessParamsContext();
  const filterValues = methodFilterPagination.watch();

  const businessUnitListData = businessUnitList?.data || [];

  const isColumnFiltered = (
    name: keyof FindPaginationBusinessParametersPayload,
    type: 'string' | 'array' = 'string',
  ): boolean => {
    if (type === 'array') {
      return (filterValues?.[name] as unknown as any[])?.length > 0 || false;
    }
    return !!filterValues?.[name];
  };

  const handleResetPagination = () => {
    methodFilterPagination.setValue('page', 1);
  };

  return useMemo(
    () => [
      {
        title: <div className='ml-2'>{t('Name')}</div>,
        key: 'name',
        width: 240,
        minWidth: 240,
        render: (_, record) => {
          const cellValue = get(record, 'name');
          if (!cellValue) return <NotAvailableText className='' />;
          return (
            <div className='flex items-center min-w-[300px]'>
              <Button
                type='link'
                className='hover:underline pl-2 text-blue-500 truncate'
                onClick={() => {
                  setPageUrlState((p) => ({ ...p, actions: 'Update', id: record.id }));
                }}
              >
                {cellValue}
              </Button>
            </div>
          );
        },
        filterDropdown: () => (
          <InputFilterDropdown
            control={methodFilterPagination.control}
            name='transactionNo'
            placeholder={t('Search')}
            onChange={() => {
              handleResetPagination();
            }}
          />
        ),
        filterIcon: () => (
          <SearchOutlined className={isColumnFiltered('name') ? 'text-blue-500' : ''} />
        ),
      },
      {
        title: t('Type'),
        width: 160,
        key: 'type',
        render: (_, record) => {
          const cellValue = record.type;
          const foundType = BPTypes.find((item) => item.value === cellValue);

          if (!foundType?.label) return <NotAvailableText className='' />;
          return <div className=''>{foundType?.label}</div>;
        },
        // filterDropdown: () => (
        //   <div className='p-2 w-[240px]'>
        //     <CoreSelect
        //       showSearch
        //       control={methodFilterPagination.control}
        //       name='type'
        //       optionsProps={BPTypes}
        //       allowClear
        //       defaultActiveFirstOption={false}
        //       placeholder={t('Select Type')}
        //       onChange={() => {
        //         handleResetPagination();
        //       }}
        //     />
        //   </div>
        // ),
        // filterIcon: () => <TitleFilterIcon active={isColumnFiltered('type')} />,
      },
      {
        title: t('Business Unit Apply'),
        width: 300,
        minWidth: 300,
        key: 'organizationScope',
        render: (_, record) => {
          if (String(record.organizationScope?.isApplyAllBusinessUnit) === 'true')
            return <div className='text-green-500 text-sm font-[600]'>{t('Apply all')}</div>;

          const mappedBusinessUnit = businessUnitListData.filter((item) =>
            record.organizationScope?.businessUnitIds.includes(item.id),
          );
          return (
            <div className='flex items-center leading-[1.2]'>
              {mappedBusinessUnit
                .map((item) => item?.name)
                .filter(Boolean)
                .join(' , ')}
            </div>
          );
        },
        // filterDropdown: () => (
        //   <div className='p-2 w-[240px]'>
        //     <CoreSelect
        //       showSearch
        //       control={methodFilterPagination.control}
        //       name='appliedBusinessUnitIds'
        //       mode='multiple'
        //       optionsProps={businessUnitListMapped}
        //       allowClear
        //       defaultActiveFirstOption={false}
        //       placeholder={t('Select Business Unit(s)')}
        //       onChange={() => {
        //         handleResetPagination();
        //       }}
        //     />
        //   </div>
        // ),
        // filterIcon: () => (
        //   <TitleFilterIcon active={isColumnFiltered('appliedBusinessUnitIds', 'array')} />
        // ),
      },
      {
        title: t('Status'),
        width: 160,
        key: 'status',
        render: (_, record) => {
          const cellValue = record.status;
          const StatusTagProps: Record<BusinessParameterStatusEnum, TagProps> = {
            [BusinessParameterStatusEnum.ACTIVE]: {
              children: 'Active',
              color: 'success',
            },
            [BusinessParameterStatusEnum.INACTIVE]: {
              children: 'Inactive',
              color: 'default',
            },
          };

          return (
            <Tag
              bordered
              color={cellValue ? 'success' : 'default'}
              className='h-7 min-w-[120px] flex items-center !text-sm font-[500]'
              {...(StatusTagProps?.[cellValue] || { children: '---', className: 'hidden' })}
            />
          );
        },
        // filterDropdown: () => (
        //   <div className='p-2 w-[240px]'>
        //     <CoreSelect
        //       showSearch
        //       control={methodFilterPagination.control}
        //       name='status'
        //       optionsProps={BPStatuses}
        //       allowClear
        //       defaultActiveFirstOption={false}
        //       placeholder={t('Select Status')}
        //       onChange={() => {
        //         handleResetPagination();
        //       }}
        //     />
        //   </div>
        // ),
        // filterIcon: () => <TitleFilterIcon active={isColumnFiltered('status')} />,
      },
      {
        title: t('Apply time'),
        key: 'timeScope',
        width: 240,
        minWidth: 240,
        render: (_, record) => {
          const cellValue = record.timeScope.appliedTime;
          if (!cellValue?.length) return <NotAvailableText />;
          return <div className='leading-[1.2]'>{formatTimeScope(cellValue)}</div>;
        },
        // filterIcon: () => <TitleFilterIcon active={false} />,
        // filterDropdown: () => (
        //   <div className='p-2 w-[240px]'>
        //     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={''} />
        //   </div>
        // ),
      },

      {
        title: t('Actions'),
        width: 100,
        align: 'center',
        fixed: 'right',
        render: (_, record) => (
          <div className={cn('flex items-center justify-center', 'invisible')}>
            <Button
              type='text'
              className='btn-center !text-blue-400 h-9 w-9 !p-0'
              onClick={() => {
                setPageUrlState((p) => ({ ...p, actions: 'Update', id: record.id }));
              }}
            >
              <EditOutlined className='text-inherit text-[16px]' />
            </Button>
          </div>
        ),
      },
    ],
    [t, JSON.stringify(filterValues), businessUnitListData],
  );
}

export default useBusinessParametersColumns;
