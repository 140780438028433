export const marketingProjectData = `
id
name
shortName
isActive
businessDomainId
createdAt
createdBy
lastModifiedAt
lastModifiedBy
lastModifiedByData {
    id
    displayName
    firebaseId
}
createdByData {
    id
    displayName
    firebaseId
}
`;
