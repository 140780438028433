// Generate code from clean architecture template

import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import * as GQL from '../../graphql';
import { MarketingProjectRepository } from '@/domains/marketing-project/ports/repositories';
import {
  CreateMarketingProjectPayload,
  FindByCriteriaPayload,
  FindMarketingProjectPayload,
  UpdateMarketingProjectPayload,
} from '@/domains/marketing-project/ports/payloads';
import { MarketingProjectEntity } from '@/domains/marketing-project/entities';
import { FindResult } from '@/core/interfaces/findResult';

export class MarketingProjectHttpRepository implements MarketingProjectRepository {
  async find(payload?: FindByCriteriaPayload): Promise<MarketingProjectEntity[]> {
    const response = await HttpClientAdapter.query<MarketingProjectEntity[]>({
      query: GQL.GET_ALL_FILTERED_MARKETING_PROJECT,
      dataKey: 'MarketingProject_getAll',
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async findById(id: string): Promise<MarketingProjectEntity | undefined> {
    const response = await HttpClientAdapter.query<MarketingProjectEntity>({
      query: GQL.GET_MARKETING_PROJECT_BY_ID,
      dataKey: 'MarketingProject_getById',
      variables: { id: id },
    });
    return response;
  }

  async findPaginate(
    payload: FindMarketingProjectPayload,
  ): Promise<FindResult<MarketingProjectEntity>> {
    const response = await HttpClientAdapter.query<FindResult<MarketingProjectEntity>>({
      query: GQL.GET_LIST_MARKETING_PROJECT,
      dataKey: 'MarketingProject_getList',
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async create(payload: CreateMarketingProjectPayload): Promise<MarketingProjectEntity> {
    const response = await HttpClientAdapter.mutate<MarketingProjectEntity>({
      mutation: GQL.CREATE_MARKETING_PROJECT,
      variables: { payload: { ...payload } },
      dataKey: 'MarketingProject_create',
    });
    return response;
  }

  async update(payload: UpdateMarketingProjectPayload): Promise<MarketingProjectEntity> {
    const response = await HttpClientAdapter.mutate<MarketingProjectEntity>({
      mutation: GQL.UPDATE_MARKETING_PROJECT,
      variables: { payload: { ...payload } },
      dataKey: 'MarketingProject_update',
    });
    return response;
  }
}
