import { Context, FetchPolicy, OperationVariables } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { initApolloClient, TProps } from './apollo-client';
import { navigateOnFailures } from '@/core';

export interface MutateDataParams {
  mutation: DocumentNode;
  variables?: Record<string, unknown>;
  successMessage?: string;
}

export interface HttpClientAdapterParams {
  query: DocumentNode;
  variables?: OperationVariables;
  dataKey?: string;
  fetchPolicy?: FetchPolicy;
  context?: Context;
  notifyOnNetworkStatusChange?: boolean;
}

export class HttpClientAdapter {
  static async query<T>(params: HttpClientAdapterParams, initOpts?: TProps): Promise<T> {
    const apolloClient = await initApolloClient(initOpts);
    const response = await apolloClient.query({
      query: params.query,
      variables: params.variables,
      fetchPolicy: params.fetchPolicy,
      context: params.context,
      notifyOnNetworkStatusChange: params.notifyOnNetworkStatusChange,
    });
    if (response.errors && response.errors.length > 0) {
      if (response.errors[0].message === 'NOT_ALLOW_REPORT_VIEW') {
        // navigateOnFailures(false, true);
      }
    }
    return params.dataKey ? response.data[`${params.dataKey}`] : response.data;
  }

  static async mutate<T>(
    {
      mutation,
      variables,
      dataKey,
    }: {
      mutation: DocumentNode;
      dataKey?: string;
      variables?: OperationVariables;
    },
    initOpts?: TProps,
  ): Promise<T> {
    const apolloClient = await initApolloClient(initOpts);
    const response = await apolloClient.mutate<T>({
      mutation,
      variables,
    });
    if (response.errors && response.errors.length > 0) {
      if (response.errors[0].message === 'NOT_ALLOW_REPORT_VIEW') {
        // navigateOnFailures(false, true);
      }
    }
    const { data }: any = response;
    if (!data) {
      throw new Error('Not Found');
    } else {
      return dataKey ? data[dataKey] : data;
    }
  }
}
