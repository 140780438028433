import { BusinessParametersEntity } from '../entities';
import {
  CreateBusinessParametersPayload,
  UpdateBusinessParametersPayload,
} from '../ports/payloads';
import { BusinessParametersRepository } from '../ports/repositories';

export class MutateBusinessParametersUsecases {
  constructor(private readonly repo: BusinessParametersRepository) {}

  async create(payload: CreateBusinessParametersPayload): Promise<BusinessParametersEntity> {
    return await this.repo.create(payload);
  }

  async update(payload: UpdateBusinessParametersPayload): Promise<BusinessParametersEntity> {
    return await this.repo.update(payload);
  }
}
