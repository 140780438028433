/* eslint-disable indent */
import { cn } from '@/common';
import FormLabel from '@/components/Form/Helpers/FormLabel';
import FormMessage from '@/components/Form/Helpers/FormMessage';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber, Select, Space, Tag, TagProps } from 'antd';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePageBusinessParamsContext } from '../context';
import { BPMeasurementUnitList } from '@/domains/business-parameters/entities';
import { get } from 'lodash';

const getTagColor = (value: number): TagProps['color'] => {
  if (value === 0) return 'success';
  if (value > 60 && value <= 100) return 'error';
  if (value < 60 && value >= 30) return 'gold';
  if (value > 0 || value < 30) return 'warning';
  return 'default';
};

type TBPDistributionProjectCombinedProps = {
  className?: string;
  methodForm: UseFormReturn<any>;
  disabledBtnAdd?: boolean;
};
const BPDistributionProjectCombined = ({
  className,
  methodForm,
  disabledBtnAdd,
}: TBPDistributionProjectCombinedProps) => {
  const { t } = useTranslation();
  const { businessUnitList, marketingProjectList } = usePageBusinessParamsContext();

  const formErrors = methodForm.formState.errors;

  const measurementUnitWatch = methodForm.watch('measurementUnit');
  const foundMeasurementUnit = BPMeasurementUnitList.find(
    (item) => item.value === measurementUnitWatch,
  );

  const marketingProjectListData = marketingProjectList?.data || [];
  const marketingProjectListMapped = marketingProjectListData?.map((item) => ({
    value: item.id,
    label: [item?.shortName, item?.name].filter(Boolean).join(' - '),
  }));

  const percentageDistributionByProjectValueFieldsForm = useFieldArray({
    control: methodForm.control,
    name: 'percentageDistributionByProjectValue',
    keyName: 'uid',
  });
  const percentageDistributionByProjectValueFieldsFormWatch = methodForm.watch(
    'percentageDistributionByProjectValue',
    [],
  ) as any[];
  const remainingPercentageDistributionByProject =
    100 -
    (Array.isArray(percentageDistributionByProjectValueFieldsFormWatch)
      ? percentageDistributionByProjectValueFieldsFormWatch.reduce(
          (acc, curr) => acc + (curr?.value || 0),
          0,
        )
      : 0);

  return (
    <div className={cn('flex flex-col mb-4', className)}>
      <div className='flex items-center mb-2'>
        <FormLabel label={`${t('Applied projects(s)')}:`} className='mb-0 mr-2' />
        <Tag
          color={getTagColor(remainingPercentageDistributionByProject)}
          bordered={false}
          className='font-[600] rounded-full mr-1 leading-[1.4]'
        >{`${remainingPercentageDistributionByProject}%`}</Tag>
        <span className='text-gray-400 text-xs font-[500]'>{t('remaining')}</span>
      </div>
      <div className='flex flex-col pl-4 relative'>
        <div className='absolute top-0 left-0 h-[calc(100%-8px)] w-[2px] bg-gray-200'></div>
        {percentageDistributionByProjectValueFieldsForm.fields.map((fItem, index) => {
          return (
            <div className='flex flex-nowrap mb-4 gap-4 relative' key={fItem.uid}>
              <div
                className={cn(
                  'absolute -left-4 w-3 h-[2px] bg-gray-200 top-1/2 -translate-y-1/2',
                  !index && 'mt-[14px]',
                )}
              ></div>
              <Space.Compact block className='flex flex-nowrap'>
                <Controller
                  control={methodForm.control}
                  name={`percentageDistributionByProjectValue.${index}.projectId`}
                  render={({ field, fieldState }) => {
                    return (
                      <div className='flex flex-col w-1/2'>
                        <FormLabel
                          required
                          label={t('Project')}
                          className={cn('', !!index && 'hidden')}
                        />
                        <Select
                          status={fieldState.error?.message ? 'error' : undefined}
                          options={marketingProjectListMapped.map((item) => ({
                            ...item,
                            disabled:
                              field.value !== item.value &&
                              percentageDistributionByProjectValueFieldsFormWatch
                                .map((item) => get(item, 'projectId'))
                                .includes(item.value),
                          }))}
                          allowClear={false}
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          placeholder={t('Select project')}
                          showSearch
                        />
                        <FormMessage content={fieldState.error?.message} />
                      </div>
                    );
                  }}
                />
                <Controller
                  control={methodForm.control}
                  name={`percentageDistributionByProjectValue.${index}.value`}
                  render={({ field, fieldState }) => {
                    return (
                      <div className={cn('flex flex-col w-1/2')}>
                        <FormLabel
                          required
                          label={t('Distributed value')}
                          className={cn('', !!index && 'hidden')}
                        />
                        <InputNumber
                          status={fieldState.error?.message ? 'error' : undefined}
                          className='[&_.ant-select-selection-item]:text-gray-800'
                          rootClassName={cn('w-full')}
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          min={0}
                          max={100}
                          suffix={foundMeasurementUnit?.label}
                        />
                        <FormMessage content={fieldState.error?.message} />
                      </div>
                    );
                  }}
                />
              </Space.Compact>
              {percentageDistributionByProjectValueFieldsFormWatch.length > 1 && (
                <div className={cn('flex flex-col', !index && 'pt-[26px]')}>
                  <Button
                    type='dashed'
                    shape='circle'
                    icon={<MinusOutlined className='text-red-500' />}
                    onClick={() => percentageDistributionByProjectValueFieldsForm.remove(index)}
                  ></Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {remainingPercentageDistributionByProject > 0 && (
        <div
          className={cn(
            'grid grid-cols-2',
            percentageDistributionByProjectValueFieldsFormWatch.length > 1 && 'pr-12',
          )}
        >
          <Button
            disabled={disabledBtnAdd}
            type='dashed'
            icon={<PlusOutlined />}
            onClick={() =>
              percentageDistributionByProjectValueFieldsForm.append({
                projectId: undefined,
                value: undefined,
              })
            }
          >
            {t('Add new project')}
          </Button>
        </div>
      )}

      {remainingPercentageDistributionByProject > 0 && (
        <FormMessage
          className='!mt-2'
          content={get(formErrors, 'percentageDistributionByProjectValue')?.message as string}
        />
      )}
    </div>
  );
};

export default BPDistributionProjectCombined;
