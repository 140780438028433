import {
  CreateBusinessParametersPayload,
  CreateBusinessParametersPayloadForm,
} from '@/domains/business-parameters/ports/payloads';
import { MutateBusinessParametersUsecases } from '@/domains/business-parameters/usecases';
import { useRequest } from 'ahooks';
import { BusinessParametersHttpRepository } from '../adapters/repositories';
import Yup from '@/yupGlobal';
import { i18nt } from '@/i18n';
import { BusinessParameterTimeUnit } from '@/domains/business-parameters/entities';

export function useCreateBusinessParametersViewmodel() {
  const MutateUsecases = new MutateBusinessParametersUsecases(
    new BusinessParametersHttpRepository(),
  );

  const Req = useRequest(
    async (payload: CreateBusinessParametersPayload) => {
      const result = await MutateUsecases.create(payload);
      return result;
    },
    { manual: true },
  );

  return Req;
}

// export const CreateSchema = Yup.object({
//   name: Yup.string().required(i18nt('common.required_field')),
// });
export const CreateSchema = Yup.object({
  name: Yup.string().trim().required('Name is required').default(''),

  type: Yup.string().required('Business parameter type is required'),

  status: Yup.string().required('Status is required'),

  measurementUnit: Yup.string().required('Measurement unit is required'),

  organizationScope: Yup.object()
    .optional()
    .shape({
      businessUnitIds: Yup.array()
        .of(Yup.string().required('Business unit ID is required'))
        .default([]),
      isApplyAllBusinessUnit: Yup.bool(),
    }),

  timeScopeValue: Yup.array()
    .optional()
    .of(
      Yup.object().shape({
        appliedTime: Yup.array()
          .of(Yup.mixed<BusinessParameterTimeUnit>().required('Time unit is required'))
          .required('Applied time is required')
          .min(1, 'At least one time unit is required'),
        singleConfigurationValue: Yup.number()
          .optional()
          .integer('Value is required')
          .typeError('Value must be a number'),
      }),
    )
    .default([]),

  projectScope: Yup.object()
    .optional()
    .shape({
      projectIds: Yup.array().of(Yup.string().required('Project ID is required')).default([]),
      isApplyAllProject: Yup.bool(),
    }),

  percentageDistributionByProjectValue: Yup.array()
    .optional()
    .of(
      Yup.object().shape({
        projectId: Yup.string().required('Project ID is required'),
        value: Yup.number()
          .required('Percentage is required')
          .min(0, 'Percentage must be non-negative')
          .max(100, 'Percentage cannot exceed 100')
          .typeError('Percentage must be a number'),
      }),
    )
    .default([])
    .test('sum-of-percentages', 'Sum of percentages must equal 100', (value: any[]) => {
      if (!value?.length) return true;
      const sum = value.reduce((acc, curr) => acc + (curr?.value || 0), 0);
      return sum === 100;
    }),
});
export type TCreateSchema = Yup.InferType<typeof CreateSchema>;
