import FormLabel from '@/components/Form/Helpers/FormLabel';
import FormMessage from '@/components/Form/Helpers/FormMessage';
import { Checkbox, Select } from 'antd';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePageBusinessParamsContext } from '../context';

type TBPOrgScopeProps = { methodForm: UseFormReturn<any>; name: string };

const BPOrgScope = ({ methodForm, name }: TBPOrgScopeProps) => {
  const { t } = useTranslation();
  const { businessUnitList } = usePageBusinessParamsContext();

  const businessUnitListData = businessUnitList?.data || [];
  const businessUnitListMapped = businessUnitListData
    .filter((item) => !!item?.parentId)
    ?.map((item) => ({
      value: item.id,
      label: item?.name || '',
    }));

  return (
    <Controller
      control={methodForm.control}
      name={name}
      render={({ field, fieldState }) => {
        const selectedBusinessUnitIds = field.value?.businessUnitIds;
        const isApplyAllBusinessUnit = !!field.value?.isApplyAllBusinessUnit;
        return (
          <div className='flex flex-col mb-4'>
            <FormLabel required label={t('Business unit applied')} />
            <Select
              disabled={isApplyAllBusinessUnit}
              options={businessUnitListMapped}
              placeholder={isApplyAllBusinessUnit ? t('Applied all') : t('Select business unit(s)')}
              showSearch
              mode='multiple'
              status={fieldState.error?.message ? 'error' : undefined}
              className='[&_.ant-select-selection-item]:text-gray-800'
              value={isApplyAllBusinessUnit ? undefined : selectedBusinessUnitIds}
              onChange={(value) => {
                field.onChange({ businessUnitIds: value || [], isApplyAllBusinessUnit: false });
              }}
            />
            <Checkbox
              className='mt-1'
              checked={!isApplyAllBusinessUnit}
              onChange={(e) => {
                const checked = e.target.checked;
                field.onChange({
                  businessUnitIds: selectedBusinessUnitIds,
                  isApplyAllBusinessUnit: !checked,
                });
              }}
            >
              {t('Apply for each business unit')}
            </Checkbox>
            <FormMessage content={fieldState.error?.message} />
          </div>
        );
      }}
    />
  );
};

export default BPOrgScope;
