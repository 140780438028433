/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, notification, Spin, Switch } from 'antd';
import { Typography } from 'antd/lib';
import React, { useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { usePageBusinessParamsContext } from '../context';
import {
  UpdateSchema,
  TUpdateSchema,
  useUpdateBusinessParametersViewmodel,
  useFindOneBusinessParametersViewmodel,
  formatToFormUpdate,
} from '../viewmodels';
import BusinessParametersForm from './BusinessParametersForm';
import { useTranslation } from 'react-i18next';
import {
  BPStatuses,
  BusinessParameterStatusEnum,
  BusinessParameterTypeEnum,
} from '@/domains/business-parameters/entities';
import { CreateBusinessParametersPayloadForm } from '@/domains/business-parameters/ports/payloads';
import { range } from 'lodash';
import { cn } from '@/common';

type TBusinessParametersUpdateModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  itemId?: string;
};

const BusinessParametersUpdateModal = ({
  open,
  onClose,
  itemId,
}: TBusinessParametersUpdateModalProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const { businessUnitList, businessParamsPagination } = usePageBusinessParamsContext();

  const FindOneBusinessParametersReq = useFindOneBusinessParametersViewmodel();

  const methodForm = useForm<TUpdateSchema>({
    mode: 'onTouched',
    resolver: yupResolver(UpdateSchema),
  });

  // const filterFieldsForm = useFieldArray({
  //   keyName: 'uid',
  //   control: methodForm.control,
  //   name: 'filter',
  // });

  const UpdateBusinessParametersReq = useUpdateBusinessParametersViewmodel();

  const handleSubmitForm = methodForm.handleSubmit(
    async (formData) => {
      try {
        if (!itemId) throw new Error('Id is required');
        const {
          type,
          timeScopeValue,
          percentageDistributionByProjectValue,
          projectScope,
          ...restFormData
        } = formData as CreateBusinessParametersPayloadForm;

        const timeScopeItem = timeScopeValue?.[0];
        const isApplyAllMonth = !timeScopeItem?.appliedTime[0]?.month;

        const isTypeRevenue = type === BusinessParameterTypeEnum.REVENUE;
        const processes = await UpdateBusinessParametersReq.runAsync({
          ...restFormData,
          id: itemId,
          projectScope: isTypeRevenue
            ? {
                projectIds: (percentageDistributionByProjectValue || [])?.map(
                  (item) => item.projectId,
                ),
                isApplyAllProject: false,
              }
            : projectScope,
          timeScope: timeScopeItem?.appliedTime
            ? {
                appliedTime: isApplyAllMonth
                  ? range(1, 13).map((m) => ({
                      month: m,
                      year: timeScopeItem?.appliedTime[0]?.year,
                    }))
                  : timeScopeItem?.appliedTime,
              }
            : undefined,
          singleConfiguration: timeScopeItem?.singleConfigurationValue
            ? { value: timeScopeItem?.singleConfigurationValue }
            : undefined,
          type,
          percentageDistributionConfiguration: isTypeRevenue
            ? { percentageDistributionByProject: percentageDistributionByProjectValue || [] }
            : undefined,
        });
        console.log(' handleSubmitForm ~ processes:', processes);

        businessParamsPagination.refresh();
        FindOneBusinessParametersReq.refresh();
        onClose?.();
        methodForm.reset();
        notification.success({ message: 'Updated Business Parameters' });
      } catch (error) {
        console.log('error:', error);
        notification.error({ message: 'Something went wrong' });
      }
    },
    (errors) => {
      console.log('errors:', errors);
    },
  );

  useEffect(() => {
    FindOneBusinessParametersReq.runAsync({ id: itemId }).then((res) => {
      methodForm.reset(formatToFormUpdate(res));
    });
  }, [itemId]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        methodForm.reset();
        onClose?.();
      }}
      title={
        <Typography.Text style={{ margin: 0 }}>{t('Update Business Parameters')}</Typography.Text>
      }
      headerStyle={{ padding: '12px 16px' }}
      bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      width={600}
      footer={null}
    >
      <Spin
        spinning={FindOneBusinessParametersReq.loading}
        wrapperClassName='h-full [&>.ant-spin-container]:h-full [&>.ant-spin-container]:flex [&>.ant-spin-container]:flex-col'
      >
        <BusinessParametersForm
          id={uid + 'form'}
          methodForm={methodForm}
          className='flex flex-col p-4'
          onSubmit={handleSubmitForm}
          mode='update'
          // filterFieldsForm={filterFieldsForm}
        />
        <div className='flex gap-4 sticky bottom-0 mt-auto bg-white border-0 border-t border-solid border-t-gray-100 h-16 p-4'>
          <Controller
            control={methodForm.control}
            name='status'
            render={({ field }) => (
              <div className='flex items-center gap-2'>
                <Switch
                  checked={field.value === 'ACTIVE'}
                  onChange={(checked) => {
                    field.onChange(checked ? 'ACTIVE' : 'INACTIVE');
                  }}
                />
                <div
                  className={cn(
                    'font-[600]',
                    field.value === 'ACTIVE' ? 'text-[#52c41a]' : 'text-gray-400',
                  )}
                >
                  {BPStatuses.find((p) => p.value === field.value)?.label}
                </div>
              </div>
            )}
          />
          <Button
            type='default'
            onClick={() => {
              methodForm.reset();
            }}
            className='ml-auto'
            disabled={UpdateBusinessParametersReq.loading}
          >
            {t('Reset')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            form={uid + 'form'}
            style={{ minWidth: 150, fontWeight: 600 }}
            disabled={UpdateBusinessParametersReq.loading}
            loading={UpdateBusinessParametersReq.loading}
          >
            {t('Save')}
          </Button>
        </div>
      </Spin>
    </Drawer>
  );
};

export default BusinessParametersUpdateModal;
