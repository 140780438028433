import { useBaseViewModel } from '@/common';
import { BusinessUnitEntity } from '@/domains/business-unit/entities';
import { GetAllBusinessUnitUsecase } from '@/domains/business-unit/usecases/get-all.usecase';
import { useState } from 'react';
import { BusinessUnitHttpRepository } from '../adapters/repositories/business-unit.http.repository';
import { useRequest } from 'ahooks';

export default function GetAllBusinessUnitViewModel() {
  const getAllBusinessUnitUsecase = new GetAllBusinessUnitUsecase(new BusinessUnitHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [buData, setBUData] = useState<BusinessUnitEntity[]>();

  const actionGetAllBU = async () => {
    await catchAction(async () => {
      const result = await getAllBusinessUnitUsecase.run();
      setBUData(result);
    });
  };

  return {
    loading,
    error,
    buData,
    actionGetAllBU,
  };
}

export function useFindAllBusinessUnitViewmodel() {
  const FindUsecase = new GetAllBusinessUnitUsecase(new BusinessUnitHttpRepository());

  const Req = useRequest(
    async () => {
      const result = await FindUsecase.run();
      return result;
    },
    { manual: true, retryCount: 2 },
  );

  return Req;
}
