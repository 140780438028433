import { cn } from '@/common/utils';
import { Table, TableProps } from 'antd';
import React from 'react';

export type AnyObject = Record<PropertyKey, any>;

type TStyledTableProps<RecordType = AnyObject> = TableProps<RecordType> & {
  children?: React.ReactNode;
};

const StyledTable = <RecordType extends AnyObject>({
  className,
  ...props
}: TStyledTableProps<RecordType>) => {
  return (
    <Table
      className={cn('[&_.ant-table-row-selected>.ant-table-cell]:!bg-gray-50', className)}
      {...props}
    />
  );
};

export default StyledTable;
