import { useAppContext } from '@/core';
import {
  AimOutlined,
  BlockOutlined,
  ClusterOutlined,
  GlobalOutlined,
  GoldOutlined,
  IdcardOutlined,
  SettingOutlined,
  SignalFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import React, { memo, useEffect, useMemo } from 'react';
import { find, flatMapDeep, get } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import useViewModel from '../../modules/menu-items/viewmodels/list';
import type { MenuProps } from '../Menu';
import { Menu } from '../Menu';
type Props = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | undefined | null,
): MenuItem => {
  return {
    key,
    icon,
    children,
    title: String(label),
    label,
  } as MenuItem;
};
const InternalSidebar = (props: Props) => {
  const { collapsed, setCollapsed, setTitle } = props;
  const { Sider } = Layout;
  const navigate = useNavigate();
  const { t } = useAppContext();

  const items = useMemo<MenuItem[]>(
    () => [
      getItem(
        t('Account'),
        '/accounts',
        <IdcardOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Roles'),
        '/roles',
        <UserOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Center'),
        '/center',
        <GlobalOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Cities'),
        '/cities',
        <GoldOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Districts'),
        '/districts',
        <ClusterOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Wards'),
        '/wards',
        <BlockOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Employees'),
        '/employees',
        <UserOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),
      getItem(
        t('Product line'),
        '/product-lines',
        <UserOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      ),

      getItem(
        t('Setting'),
        '/setting',
        <SettingOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
        [getItem(t('Business Parameters'), '/configure-business-parameters')],
      ),
    ],
    [],
  );

  const getChildren = (initItem: any): any => {
    const item = { ...initItem };
    delete item.children;
    delete item.icon;
    delete item.label;
    if (!initItem.children || !initItem.children.length) {
      return item;
    }
    return [item, flatMapDeep(initItem.children, getChildren)];
  };
  const flattenMenuItems = useMemo(() => flatMapDeep(items, getChildren), [items]);

  useEffect(() => {
    const foundItem = find(flattenMenuItems, { key: location.pathname });
    if (foundItem) setTitle(get(foundItem, 'title'));
  }, [location.pathname]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ background: '#001529' }}
      width={236}
      className='[&_.ant-layout-sider-children]:flex [&_.ant-layout-sider-children]:flex-col h-[100dvh] !sticky top-0'
    >
      <div className='sticky top-0'>
        <Link to='/' className='text-white flex items-center h-[66px] gap-[10px] shrink-0 px-3'>
          {collapsed ? (
            <img src='/favicon.png' alt='logo' className='w-10 h-10 mx-auto' />
          ) : (
            <div>
              <div className='w-full flex gap-3'>
                <img src='/favicon.png' alt='logo' className='w-9 h-9' />
                <h1 className='font-sans text-white text-lg mt-2'>MindX Base BETA</h1>
              </div>
            </div>
          )}
        </Link>
        <Menu
          className='px-1 [&_.ant-menu-sub.ant-menu-inline]:!bg-sky-900/10 [&_.ant-menu-sub.ant-menu-inline]:rounded [&_.ant-menu-item-icon]:!text-[16px]'
          onClick={(onClickParams) => {
            const { key: path } = onClickParams;
            navigate(path);
          }}
          onSelect={(onSelectParams) => {
            const labelSelect = items.find((i) => {
              return i?.key === onSelectParams.key
                ? true
                : get(i, 'children', []).some((item) => item?.key === onSelectParams.key);
            });
            setTitle(get(labelSelect, 'label', '') as unknown as string);
          }}
          theme='dark'
          selectedKeys={[location.pathname]}
          inlineIndent={12}
          mode='inline'
          items={items}
        />
      </div>
    </Sider>
  );
};

export const Sidebar = memo(InternalSidebar);
