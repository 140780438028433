import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './static/locales/en/common.json';
import common_vi from './static/locales/vi/common.json';
import error_vi from './static/locales/vi/message_error.json';
import error_en from './static/locales/en/message_error.json';

import { config } from './config';

const resources = {
  en: {
    translations: {
      common: common_en,
      error: error_en,
    },
  },
  vi: {
    translations: {
      common: common_vi,
      error: error_vi,
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lang') || 'vi',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, 
    },
    fallbackLng: 'en',
  });

// type TranslationKeys
/**
 * Dùng khi gọi ở bên NGOÀI react-hook,react-component
 */
export const i18nt = (key: string, values?: Record<string, string | number>) =>
  i18n.t(key, values || {});

export default i18n;
