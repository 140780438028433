/* eslint-disable indent */
import { cn } from '@/common';
import FormLabel from '@/components/Form/Helpers/FormLabel';
import FormMessage from '@/components/Form/Helpers/FormMessage';
import {
  BPMeasurementUnitList,
  BPTypes,
  BusinessParameterTypeEnum,
  MeasurementUnitEnum,
} from '@/domains/business-parameters/entities';
import { Divider, Input, Select, TagProps } from 'antd';
import React from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePageBusinessParamsContext } from '../context';
import BPPercentageDistributionByProjectCombined from './BPDistributionProjectCombined';
import BPOrgScope from './BPOrgScope';
import BPProjectScope from './BPProjectScope';
import BPTimeScopeCombined from './BPTimeScopeCombined';
import dayjs from 'dayjs';

type TBusinessParametersFormProps = {
  methodForm: UseFormReturn<any>;
  id: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  mode?: 'create' | 'update';
};

const getTagColor = (value: number): TagProps['color'] => {
  if (value === 0) return 'success';
  if (value > 60 && value <= 100) return 'error';
  if (value < 60 && value >= 30) return 'gold';
  if (value > 0 || value < 30) return 'warning';
  return 'default';
};

const BusinessParametersForm = ({
  methodForm,
  id,
  onSubmit,
  className,
  mode = 'create',
}: TBusinessParametersFormProps) => {
  const { t } = useTranslation();
  const { businessUnitList, marketingProjectList } = usePageBusinessParamsContext();

  const timeScopeValueFieldsFormWatch = methodForm.watch('timeScopeValue', []) as any[];
  const timeScopeAppliedTimeMapped = (timeScopeValueFieldsFormWatch || []).map((item) =>
    item?.appliedTime?.map(JSON.stringify),
  );

  const isTypeRevenue = methodForm.watch('type') === BusinessParameterTypeEnum.REVENUE;

  const selectedYear = timeScopeValueFieldsFormWatch?.[0]?.appliedTime?.[0]?.year || dayjs().year();

  const handleChangeName = (params: {
    leftValue?: string;
    rightValue?: string;
    overrideValue?: string;
  }) => {
    const value =
      params?.overrideValue ||
      [params?.leftValue, methodForm.getValues('name'), params?.rightValue]
        .filter(Boolean)
        .join('');
    methodForm.setValue('name', value || '');
  };

  return (
    <FormProvider {...methodForm}>
      <form id={id} onSubmit={onSubmit} className={cn('flex flex-col', className)}>
        <div className='grid grid-cols-2 gap-4 mb-4'>
          <Controller
            control={methodForm.control}
            name='type'
            render={({ field, fieldState }) => (
              <div className='flex flex-col'>
                <FormLabel required label={t('Type')} />
                <Select
                  disabled={mode === 'update'}
                  options={BPTypes}
                  showSearch={false}
                  status={fieldState.error?.message ? 'error' : undefined}
                  className=''
                  value={field.value}
                  onSelect={(value) => {
                    field.onChange(value);
                    handleChangeName({
                      overrideValue: `${value} - ${String(selectedYear)}`,
                    });
                    methodForm.resetField('timeScopeValue');
                    methodForm.resetField('projectScope');
                    methodForm.resetField('organizationScope');
                    switch (value) {
                      case BusinessParameterTypeEnum.AOV: {
                        methodForm.setValue('measurementUnit', MeasurementUnitEnum.VND);
                        methodForm.resetField('percentageDistributionByProjectValue');
                        return;
                      }
                      case BusinessParameterTypeEnum.CR16: {
                        methodForm.setValue('measurementUnit', MeasurementUnitEnum.PERCENT);
                        methodForm.resetField('percentageDistributionByProjectValue');
                        return;
                      }
                      case BusinessParameterTypeEnum.REVENUE: {
                        methodForm.setValue('measurementUnit', MeasurementUnitEnum.PERCENT);
                        methodForm.setValue('percentageDistributionByProjectValue', [
                          { projectId: undefined, value: undefined },
                        ]);
                        return;
                      }
                    }
                  }}
                />
                <FormMessage content={fieldState.error?.message} />
              </div>
            )}
          />

          <Controller
            control={methodForm.control}
            name='measurementUnit'
            render={({ field, fieldState }) => (
              <div className='flex flex-col'>
                <FormLabel label={t('Unit of value')} />
                <Select
                  disabled
                  options={BPMeasurementUnitList}
                  showSearch={false}
                  status={fieldState.error?.message ? 'error' : undefined}
                  className='[&_.ant-select-selection-item]:text-gray-800'
                  value={field.value}
                  onSelect={(value) => {
                    field.onChange(value);
                  }}
                />
                <FormMessage content={fieldState.error?.message} />
              </div>
            )}
          />
        </div>

        {!isTypeRevenue && <BPProjectScope methodForm={methodForm} name='projectScope' />}

        <BPPercentageDistributionByProjectCombined
          methodForm={methodForm}
          className={cn('flex flex-col mb-4', !isTypeRevenue && 'hidden')}
          disabledBtnAdd={timeScopeAppliedTimeMapped.flat(1).length === 12}
        />

        <BPOrgScope methodForm={methodForm} name='organizationScope' />

        <Divider className='my-0' />
        <h3 className='text-sm font-[600] mt-2 mb-4 text-gray-500'>{t('Applied time')}</h3>

        <BPTimeScopeCombined
          methodForm={methodForm}
          hideAddBtn={mode === 'create' && !isTypeRevenue}
        />

        <Divider className='mt-6 mb-2' />
        <h3 className='text-sm font-[600] mb-2 text-gray-500'>{t('Others')}</h3>

        <Controller
          control={methodForm.control}
          name='name'
          render={({ field, fieldState }) => (
            <div className='flex flex-col mb-4'>
              <FormLabel label={t('Name')} />
              <Input
                status={fieldState.error?.message ? 'error' : undefined}
                className=''
                value={field.value}
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value);
                }}
              />
              <FormMessage content={fieldState.error?.message} />
            </div>
          )}
        />
      </form>
    </FormProvider>
  );
};

export default BusinessParametersForm;
