import { gql } from '@apollo/client';
import { UsersData } from './users_data';

export const GET_USER_BY_ID = gql`
query User_getById($id: String!) {
  User_getById(id: $id) {
    ${UsersData}
  }
}
`;

export const GET_USER_BY_FIREBASE_ID = gql`
query User_getById($id: String!) {
  User_getById(id: $id) {
    ${UsersData}
  }
}
`;
