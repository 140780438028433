import { Config } from '@/config/index';

export const overrideConfig: Partial<Config> = {
  firebase: {
    apiKey: 'AIzaSyB-x8gFuVKzbIoB1aYKbG1jrvm8mbZUmkQ',
    authDomain: 'mindx-edu-dev.firebaseapp.com',
    databaseURL: 'https://mindx-edu-dev.firebaseio.com',
    projectId: 'mindx-edu-dev',
    storageBucket: 'mindx-edu-dev.appspot.com',
    messagingSenderId: '592744290445',
    appId: '1:592744290445:web:aa82213d363f9b59c5eac4',
    measurementId: 'G-QPEELWB8Q4',
  },
  base: {
    webUrl: 'https://base-dev.mindx.edu.vn',
    apiUrl: 'https://gateway-staging.mindx.edu.vn/base-beta-api-dev/graphql',
    baseUrl: 'https://base-api-dev.mindx.edu.vn',
    rootEndpoint: 'https://resources-dev.mindx.edu.vn',
    uploadEndpoint: 'https://resources-dev.mindx.edu.vn/api/v1/resources',
    imageEndpoint: 'https://resources-dev.mindx.edu.vn/uploads/images',
    templateSource: '/uploads/documents/File update TMS -1723549263903-3516.xlsx',
  },
  powerbiUrl: {
    link: 'app.powerbi.com',
    group: 'groups',
    report: 'report',
  },
};
