import { ApolloClient, ApolloClientOptions, InMemoryCache } from '@apollo/client';
// import { onError } from '@apollo/client/link/error';
import { apiUrl } from './config';
import { getAuth } from 'firebase/auth';

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path, extensions }) => {
//       console.log(
//         `>>> [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//       );
//       console.log({ extensions });
//     });
//   if (networkError) console.log(`>>> [Network error]: ${networkError}`);
// });
//
// const httpLink = new HttpLink({ uri: apiUrl });

// export const httpClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: from([errorLink, httpLink]),
// });

export type TProps = Pick<ApolloClientOptions<any>, 'uri'>;

export const initApolloClient = async (props?: TProps): Promise<ApolloClient<any>> => {
  const auth = getAuth();
  const userId = await auth.currentUser?.uid;
  const idToken = await auth.currentUser?.getIdToken();
  const headers = {
    Authorization: `Bearer ${idToken}`,
  };

  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: props?.uri || apiUrl,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    headers,
  });
};
