import { FindMarketingProjectUsecase } from '@/domains/marketing-project/usecases';
import { useRequest } from 'ahooks';
import { notification } from 'antd';
import { MarketingProjectHttpRepository } from '../adapters/repositories';
import {
  FindByCriteriaPayload,
  FindMarketingProjectPayload,
} from '@/domains/marketing-project/ports/payloads';
import {
  MarketingProjectEntity,
  SampleMarketingProjects,
} from '@/domains/marketing-project/entities';
import { FindResult } from '@/core/interfaces/findResult';

export function useFindPaginationMarketingProjectViewmodel() {
  const findUC = new FindMarketingProjectUsecase(new MarketingProjectHttpRepository());
  const Req = useRequest(
    async (payload: FindMarketingProjectPayload) => {
      try {
        const { createdAt, ...rest } = payload || {};
        const result = await findUC.findPaginate({ ...rest });
        return result;
      } catch (error) {
        console.log(error);
      }
      return { data: [], total: 0, limit: 20, page: 1 } as FindResult<MarketingProjectEntity>;
    },
    { manual: true },
  );

  return Req;
}

export function useFindAllMarketingProjectViewmodel() {
  const findUC = new FindMarketingProjectUsecase(new MarketingProjectHttpRepository());

  const Req = useRequest(
    async (payload: FindByCriteriaPayload) => {
      const result = await findUC.find(payload);
      return result;
    },
    { manual: true, retryCount: 2 },
  );

  return Req;
}

export function useFindOneMarketingProjectViewmodel() {
  const findUC = new FindMarketingProjectUsecase(new MarketingProjectHttpRepository());

  const Req = useRequest(
    async (id: string) => {
      try {
        const result = await findUC.findById(id);
        return result;
      } catch (error) {
        console.log(' error:', error);
      }
      return {} as MarketingProjectEntity;
    },
    { manual: true },
  );

  return Req;
}
