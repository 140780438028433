import { cn } from '@/common';
import { Input, Typography } from 'antd';
import { get } from 'lodash';
import React, { memo, useId, useState } from 'react';
import { Controller } from 'react-hook-form';

type TInputFilterDropdownProps = {
  className?: string;
  placeholder?: string;
  control: any;
  name: string;
  onChange?: (v?: string) => void;
};

const InputFilterDropdown = (props: TInputFilterDropdownProps) => {
  const uid = useId();
  const [internalValue, setInternalValue] = useState('');
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <div className={cn('p-2 max-w-[240px]', props?.className)}>
          <Input
            autoFocus
            placeholder={props?.placeholder || `Search ${props.name}...`}
            allowClear
            key={uid + props.name + field.value}
            defaultValue={(field.value || '') as string}
            value={internalValue}
            onChange={(e) => setInternalValue(get(e, 'target.value', ''))}
            onPressEnter={(e) => {
              const newValue = get(e, 'target.value', '');
              field.onChange(newValue);
              props?.onChange?.(newValue);
            }}
            onBlur={(e) => {
              const newValue = get(e, 'target.value', '');
              field.onChange(newValue);
              props?.onChange?.(newValue);
            }}
            suffix={
              <button
                className='outline-none flex items-center justify-center bg-transparent border-none p-0 cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  field.onChange(internalValue);
                  props?.onChange?.(internalValue);
                }}
              >
                <Typography.Text keyboard className='[&_kbd]:!mx-0 -mr-1 text-xs'>
                  Enter
                </Typography.Text>
              </button>
            }
          />
        </div>
      )}
    />
  );
};

export default memo(InputFilterDropdown);
