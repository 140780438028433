import { FindResult } from '@/common';
import { BusinessParametersEntity } from '../entities';
import {
  FindOneBusinessParametersPayload,
  FindPaginationBusinessParametersPayload,
} from '../ports/payloads';
import { BusinessParametersRepository } from '../ports/repositories';

export class FindBusinessParametersUsecases {
  constructor(private readonly repo: BusinessParametersRepository) {}

  async findPagination(
    payload: FindPaginationBusinessParametersPayload,
  ): Promise<FindResult<BusinessParametersEntity>> {
    return await this.repo.findPagination(payload);
  }

  async findOne(payload: FindOneBusinessParametersPayload): Promise<BusinessParametersEntity> {
    return await this.repo.findOne(payload);
  }
}
