/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, notification, Spin, Switch } from 'antd';
import { Typography } from 'antd/lib';
import React, { useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { usePageBusinessParamsContext } from '../context';
import { CreateSchema, TCreateSchema, useCreateBusinessParametersViewmodel } from '../viewmodels';
import BusinessParametersForm from './BusinessParametersForm';
import { useTranslation } from 'react-i18next';
import {
  BPStatuses,
  BusinessParameterStatusEnum,
  BusinessParameterTypeEnum,
} from '@/domains/business-parameters/entities';
import { CreateBusinessParametersPayloadForm } from '@/domains/business-parameters/ports/payloads';
import { range } from 'lodash';
import { cn } from '@/common';

type TBusinessParametersCreateModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const BusinessParametersCreateModal = ({ open, onClose }: TBusinessParametersCreateModalProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const { businessUnitList, businessParamsPagination } = usePageBusinessParamsContext();

  const methodForm = useForm<TCreateSchema>({
    mode: 'onTouched',
    resolver: yupResolver(CreateSchema),
    defaultValues: {
      name: '',
      organizationScope: {
        businessUnitIds: [],
        isApplyAllBusinessUnit: true,
      } as TCreateSchema['organizationScope'],
      projectScope: { projectIds: [], isApplyAllProject: true } as any,
      timeScopeValue: [{ appliedTime: [{ year: 2025 }], singleConfigurationValue: undefined }],
      type: undefined,
      measurementUnit: undefined,
      percentageDistributionByProjectValue: undefined,
      status: BusinessParameterStatusEnum.ACTIVE,
    },
  });
  // const filterFieldsForm = useFieldArray({
  //   keyName: 'uid',
  //   control: methodForm.control,
  //   name: 'filter',
  // });

  const CreateBusinessParametersReq = useCreateBusinessParametersViewmodel();

  const handleSubmitForm = methodForm.handleSubmit(
    async (formData) => {
      try {
        const {
          type,
          timeScopeValue,
          percentageDistributionByProjectValue,
          projectScope,
          status,
          ...restFormData
        } = formData as CreateBusinessParametersPayloadForm;
        const isTypeRevenue = type === BusinessParameterTypeEnum.REVENUE;
        (timeScopeValue || []).forEach(async (timeScopeItem) => {
          const appliedTime = timeScopeItem.appliedTime;
          const isApplyAllMonth = !appliedTime[0]?.month;

          const res = await CreateBusinessParametersReq.runAsync({
            ...restFormData,
            status: status || BusinessParameterStatusEnum.ACTIVE,
            projectScope: isTypeRevenue
              ? {
                  projectIds: (percentageDistributionByProjectValue || [])?.map(
                    (item) => item.projectId,
                  ),
                  isApplyAllProject: false,
                }
              : projectScope,
            timeScope: {
              appliedTime: isApplyAllMonth
                ? range(1, 13).map((m) => ({ month: m, year: appliedTime[0]?.year }))
                : appliedTime,
            },
            singleConfiguration: { value: timeScopeItem.singleConfigurationValue },
            type,
            percentageDistributionConfiguration: isTypeRevenue
              ? { percentageDistributionByProject: percentageDistributionByProjectValue || [] }
              : undefined,
          });

          if (res?.id) {
            notification.success({ message: 'Created new business parameter' });
          } else {
            notification.error({ message: 'Something went wrong' });
          }
        });

        businessParamsPagination.refresh();
        onClose?.();
        methodForm.reset();
      } catch (error) {
        notification.error({ message: 'Something went wrong' });
      }
    },
    (errors) => {
      console.log(errors);
    },
  );

  return (
    <Drawer
      open={open}
      onClose={() => {
        methodForm.reset();
        onClose?.();
      }}
      title={
        <Typography.Text style={{ margin: 0 }}>{t('Create Business Parameters')}</Typography.Text>
      }
      headerStyle={{ padding: '12px 16px' }}
      bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      width={600}
      footer={null}
    >
      <Spin
        spinning={CreateBusinessParametersReq.loading}
        wrapperClassName='h-full [&>.ant-spin-container]:h-full [&>.ant-spin-container]:flex [&>.ant-spin-container]:flex-col'
      >
        <BusinessParametersForm
          id={uid + 'form'}
          methodForm={methodForm}
          className='flex flex-col p-4'
          onSubmit={handleSubmitForm}
          // filterFieldsForm={filterFieldsForm}
        />
        <div className='flex gap-4 sticky mt-auto bottom-0 bg-white border-0 border-t border-solid border-t-gray-100 h-16 p-4'>
          <Controller
            control={methodForm.control}
            name='status'
            render={({ field }) => (
              <div className='flex items-center gap-2'>
                <Switch
                  checked={field.value === 'ACTIVE'}
                  onChange={(checked) => {
                    field.onChange(checked ? 'ACTIVE' : 'INACTIVE');
                  }}
                />
                <div
                  className={cn(
                    'font-[600]',
                    field.value === 'ACTIVE' ? 'text-[#52c41a]' : 'text-gray-400',
                  )}
                >
                  {BPStatuses.find((p) => p.value === field.value)?.label}
                </div>
              </div>
            )}
          />

          <Button
            type='default'
            onClick={() => {
              methodForm.reset();
            }}
            className='ml-auto'
            disabled={CreateBusinessParametersReq.loading}
          >
            {t('Reset')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            form={uid + 'form'}
            style={{ minWidth: 150, fontWeight: 600 }}
            disabled={CreateBusinessParametersReq.loading}
            loading={CreateBusinessParametersReq.loading}
          >
            {t('Save')}
          </Button>
        </div>
      </Spin>
    </Drawer>
  );
};

export default BusinessParametersCreateModal;
