import { cn } from '@/common';
import FormLabel from '@/components/Form/Helpers/FormLabel';
import FormMessage from '@/components/Form/Helpers/FormMessage';
import {
  BPMeasurementUnitList,
  BusinessParameterTimeUnit,
  BusinessParameterTypeEnum,
  MeasurementUnitEnum,
} from '@/domains/business-parameters/entities';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import { get, range } from 'lodash';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const monthList = range(1, 13);

type TBPTimeScopeCombinedProps = { methodForm: UseFormReturn<any>; hideAddBtn?: boolean };
const BPTimeScopeCombined = ({ methodForm, hideAddBtn }: TBPTimeScopeCombinedProps) => {
  const { t } = useTranslation();

  const isTypeRevenue = methodForm.watch('type') === BusinessParameterTypeEnum.REVENUE;

  const timeScopeValueFieldsForm = useFieldArray({
    control: methodForm.control,
    name: 'timeScopeValue',
    keyName: 'uid',
  });
  const timeScopeValueFieldsFormWatch = methodForm.watch('timeScopeValue', []) as any[];
  const timeScopeAppliedTimeMapped = (timeScopeValueFieldsFormWatch || []).map((item) =>
    item?.appliedTime?.map(JSON.stringify),
  );

  const isApplyAllMonth =
    !timeScopeValueFieldsFormWatch?.[0]?.appliedTime?.[0]?.month ||
    timeScopeValueFieldsFormWatch?.[0]?.appliedTime?.length === 12;
  const selectedYear = timeScopeValueFieldsFormWatch?.[0]?.appliedTime?.[0]?.year || dayjs().year();
  const selectedYearDayjs = dayjs().year(selectedYear);

  const measurementUnitWatch = methodForm.watch('measurementUnit');
  const foundMeasurementUnit = BPMeasurementUnitList.find(
    (item) => item.value === measurementUnitWatch,
  );

  const handleYearChange = (date: dayjs.Dayjs | null, onFieldChange?: (newValue: any) => void) => {
    if (!date) return;
    const updatedTimeScopeValue = timeScopeValueFieldsFormWatch.map((item, index) => ({
      singleConfigurationValue: item.singleConfigurationValue,
      appliedTime: (item.appliedTime as BusinessParameterTimeUnit[]).map((aItem) => ({
        month: aItem.month,
        year: date.year(),
      })),
    }));
    timeScopeValueFieldsForm.replace(updatedTimeScopeValue);
  };

  return (
    <div className='flex flex-col'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='flex flex-col'>
          <FormLabel required label={t('Year')} />
          <DatePicker
            picker='year'
            className='[&_.ant-select-selection-item]:text-gray-800'
            // value={selectedBusinessUnitIds}
            allowClear={false}
            value={selectedYearDayjs}
            onChange={(value) => {
              handleYearChange(value);
            }}
            minDate={dayjs()}
          />
          <Checkbox
            className='mt-1'
            checked={!isApplyAllMonth}
            onChange={(e) => {
              const checked = e.target.checked;
              if (!checked) {
                timeScopeValueFieldsForm.replace([
                  {
                    appliedTime: [{ year: selectedYear }],
                    singleConfigurationValue: undefined,
                  },
                ]);
              } else {
                timeScopeValueFieldsForm.replace([
                  {
                    appliedTime: [{ month: 1, year: selectedYear }],
                    singleConfigurationValue: undefined,
                  },
                ]);
              }
            }}
          >
            {t('Apply for each month')}
          </Checkbox>
        </div>
        <Controller
          control={methodForm.control}
          name={`timeScopeValue.${0}.singleConfigurationValue`}
          render={({ field, fieldState }) => {
            return (
              <div
                className={cn(
                  'flex flex-col',
                  !isApplyAllMonth && 'hidden',
                  isTypeRevenue && 'hidden',
                )}
              >
                <FormLabel required label={t('Applied value')} />
                <InputNumber
                  disabled={!isApplyAllMonth}
                  status={fieldState.error?.message ? 'error' : undefined}
                  className='[&_.ant-select-selection-item]:text-gray-800'
                  value={field.value}
                  rootClassName='w-full'
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  min={0}
                  suffix={foundMeasurementUnit?.label}
                  max={
                    foundMeasurementUnit?.value === MeasurementUnitEnum.PERCENT ? 100 : undefined
                  }
                  step={foundMeasurementUnit?.value === MeasurementUnitEnum.VND ? 1000 : undefined}
                />
                <FormMessage content={fieldState.error?.message} />
              </div>
            );
          }}
        />
      </div>

      <div className={cn('flex flex-col mt-4', isApplyAllMonth && 'hidden')}>
        {/* <FormLabel required label={t('Applied value(s)')} className='mb-2' /> */}

        <div className='pl-4 flex flex-col relative'>
          <div className='absolute top-0 left-0 h-[calc(100%-8px)] w-[2px] bg-gray-200'></div>
          <div className='grid grid-cols-2 gap-2'>
            <FormLabel required label={t('Month(s)')} />
            <FormLabel
              className={isTypeRevenue ? 'hidden' : ''}
              required
              label={t('Applied value')}
            />
          </div>
          {timeScopeValueFieldsForm.fields.map((fItem, index) => {
            const fItemWatch = timeScopeValueFieldsFormWatch[index];
            return (
              <div className='flex flex-nowrap gap-4 mb-4 relative' key={fItem.uid}>
                <div
                  className={cn(
                    'absolute -left-4 w-3 h-[2px] bg-gray-200 top-1/2 -translate-y-1/2',
                  )}
                ></div>

                <div className='flex flex-nowrap gap-2 w-full p-2 border bg-gray-50 rounded-md'>
                  <Controller
                    control={methodForm.control}
                    name={`timeScopeValue.${index}.appliedTime`}
                    render={({ field, fieldState }) => {
                      const selectedMonthYears = field.value;
                      const mappedValue = selectedMonthYears.map(JSON.stringify);
                      const otherIndexesItems = timeScopeAppliedTimeMapped
                        .filter((_, i) => i !== index)
                        .flat();
                      return (
                        <div className={cn('flex flex-col w-1/2', isTypeRevenue && 'w-full')}>
                          <Select
                            mode='multiple'
                            status={fieldState.error?.message ? 'error' : undefined}
                            className='[&_.ant-select-selection-item]:text-gray-800 w-full'
                            virtual={false}
                            popupClassName={cn(
                              'ant-select-custom-scrollbar',
                              isTypeRevenue &&
                                '[&_.rc-virtual-list-holder-inner]:!flex-row [&_.rc-virtual-list-holder-inner]:flex-wrap',
                              isTypeRevenue &&
                                '[&_.rc-virtual-list-holder-inner]:gap-[4px] [&_.rc-virtual-list-holder-inner>*]:w-[calc(50%-4px)]',
                            )}
                            options={monthList.map((mItem) => {
                              const itemValue = JSON.stringify({
                                month: mItem,
                                year: selectedYear,
                              });
                              return {
                                value: itemValue,
                                label: `${t('Tháng')} ${mItem}`,
                                disabled: otherIndexesItems.includes(itemValue),
                              };
                            })}
                            allowClear={false}
                            value={selectedMonthYears.map(JSON.stringify)}
                            onChange={(value) => {
                              if (value?.length === 0) {
                                field.onChange([{ year: selectedYear }]);
                                return;
                              }
                              const values = (value as string[]).map((item) => JSON.parse(item));
                              field.onChange(values);
                            }}
                          />
                          <FormMessage content={fieldState.error?.message} />
                        </div>
                      );
                    }}
                  />
                  <Controller
                    control={methodForm.control}
                    name={`timeScopeValue.${index}.singleConfigurationValue`}
                    render={({ field, fieldState }) => {
                      return (
                        <div className={cn('flex flex-col w-1/2', isTypeRevenue && 'hidden')}>
                          <InputNumber
                            status={fieldState.error?.message ? 'error' : undefined}
                            className='[&_.ant-select-selection-item]:text-gray-800'
                            rootClassName={cn('w-full')}
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                            min={0}
                            suffix={foundMeasurementUnit?.label}
                            max={
                              foundMeasurementUnit?.value === MeasurementUnitEnum.PERCENT
                                ? 100
                                : undefined
                            }
                            step={
                              foundMeasurementUnit?.value === MeasurementUnitEnum.VND
                                ? 1000
                                : undefined
                            }
                          />
                          <FormMessage content={fieldState.error?.message} />
                        </div>
                      );
                    }}
                  />
                </div>

                {!isTypeRevenue && timeScopeValueFieldsFormWatch.length > 1 && (
                  <div className={cn('flex flex-col items-center justify-center')}>
                    <Button
                      type='dashed'
                      shape='circle'
                      icon={<MinusOutlined className='text-red-500' />}
                      onClick={() => timeScopeValueFieldsForm.remove(index)}
                    ></Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {!!hideAddBtn && (
          <div
            className={cn('grid grid-cols-2', timeScopeValueFieldsFormWatch.length > 1 && 'pr-12')}
          >
            <Button
              disabled={timeScopeAppliedTimeMapped.flat(1).length === monthList.length}
              type='dashed'
              icon={<PlusOutlined />}
              onClick={() =>
                timeScopeValueFieldsForm.append({
                  appliedTime: [],
                  singleConfigurationValue: undefined,
                })
              }
            >
              {t('Add new group')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BPTimeScopeCombined;
