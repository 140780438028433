import { FindResult } from '@/core/interfaces/findResult';
import { MarketingProjectEntity } from '../entities';
import { FindByCriteriaPayload, FindMarketingProjectPayload } from '../ports/payloads';
import { MarketingProjectRepository } from '../ports/repositories';

export class FindMarketingProjectUsecase {
  constructor(private readonly repo: MarketingProjectRepository) {}

  async find(payload?: FindByCriteriaPayload): Promise<MarketingProjectEntity[]> {
    return this.repo.find(payload);
  }

  async findPaginate(
    payload: FindMarketingProjectPayload,
  ): Promise<FindResult<MarketingProjectEntity>> {
    return this.repo.findPaginate(payload);
  }

  async findById(id: string): Promise<MarketingProjectEntity | undefined> {
    return this.repo.findById(id);
  }
}
