import { BusinessParameterTimeUnit } from '@/domains/business-parameters/entities';
import { isEqual, range, uniq } from 'lodash';

export function formatTimeScope(appliedTime: BusinessParameterTimeUnit[]): string {
  // Check if appliedTime is an array with at least one entry
  if (!Array.isArray(appliedTime) || appliedTime.length === 0) {
    return 'No time scope specified';
  }

  // Case 1: If all entries lack a month, return just the year(s)
  const hasMonths = appliedTime.some((time) => typeof time.month === 'number');
  if (!hasMonths) {
    const years = uniq(
      appliedTime.map((time) => time.year).filter((year) => typeof year === 'number'),
    );
    return years.length === 1 ? `${years[0]}` : years.join(', ');
  }

  // Case 2: Check if it covers all 12 months of a single year
  if (appliedTime.length === 12) {
    const years = uniq(
      appliedTime.map((time) => time.year).filter((year) => typeof year === 'number'),
    );
    if (years.length === 1) {
      const months = appliedTime.map((time) => time.month).sort((a, b) => (a || 0) - (b || 0));
      const allMonths = range(1, 13); // [1, 2, ..., 12]
      if (isEqual(months, allMonths)) {
        return `${years[0]}`; // Summarize as just the year
      }
    }
  }

  // Default: Display full appliedTime with month and year
  return appliedTime
    .map((time) => {
      if (typeof time.month === 'number' && typeof time.year === 'number') {
        return `${time.month}/${time.year}`;
      } else if (typeof time.year === 'number') {
        return `${time.year}`;
      }
      return 'Invalid time';
    })
    .join(', ');
}
