import { cn } from '@/common';
import React from 'react';

type TFormMessageProps = { content?: React.ReactNode; className?: string };

const FormMessage = ({ content, className = '' }: TFormMessageProps) => {
  return <div className={cn('italic text-[#F5222D] text-[13px] mb-0', className)}>{content}</div>;
};

export default FormMessage;
