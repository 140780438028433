import { gql } from '@apollo/client';
import { marketingProjectData } from './marketing-project-data';

export const GET_ALL_FILTERED_MARKETING_PROJECT = gql`
  query MarketingProject_getAll($payload: FindByCriteriaPayload) {
    MarketingProject_getAll(payload: $payload) {
      ${marketingProjectData}
    }
  }
`;

export const GET_LIST_MARKETING_PROJECT = gql`
  query MarketingProject_getList($payload: FindMarketingProjectPayload) {
    MarketingProject_getList(payload: $payload) {
      data {
        ${marketingProjectData}
      }
      count
      page
      pages
      limit
    }
  }
`;

export const GET_MARKETING_PROJECT_BY_ID = gql`
  query MarketingProject_getById($id: String!) {
    MarketingProject_getById(id: $id) {
      ${marketingProjectData}
    }
  }
`;

export const CREATE_MARKETING_PROJECT = gql`
  mutation MarketingProject_create($payload: CreateMarketingProjectPayload!) {
    MarketingProject_create(payload: $payload){
      ${marketingProjectData}
    }
  }
`;

export const UPDATE_MARKETING_PROJECT = gql`
  mutation MarketingProject_update($payload: UpdateMarketingProjectPayload!) {
    MarketingProject_update(payload: $payload){
      ${marketingProjectData}
    }
  }
`;
