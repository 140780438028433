import { UsersEntity } from '@/domain/entities/users';
import { UsersRepository } from '@/domain/ports/users/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_USER_BY_FIREBASE_ID, GET_USER_BY_ID } from '../../graphql';

export class UsersRepoIml implements UsersRepository {
  async get(payload: string): Promise<{ dataDetail: UsersEntity }> {
    const response: UsersEntity = await HttpClientAdapter.query<UsersEntity>({
      query: GET_USER_BY_ID,
      dataKey: 'User_getById',
      variables: { id: payload },
    });
    return {
      dataDetail: response,
    };
  }

  async findFirebaseId(payload: string): Promise<UsersEntity> {
    const response: UsersEntity = await HttpClientAdapter.query<UsersEntity>({
      query: GET_USER_BY_FIREBASE_ID,
      dataKey: 'User_getById',
      variables: { id: payload },
    });
    return response;
  }
}
