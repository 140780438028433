import { FindPaginationBusinessParametersPayload } from '@/domains/business-parameters/ports/payloads';
import React, { createContext, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useFindPaginationBusinessParametersViewmodel } from '../viewmodels';
import { pick } from 'lodash';
import { BusinessParametersEntity } from '@/domains/business-parameters/entities';
import { useUrlState } from '@/core/hooks/useUrlState';
import { useFindAllBusinessUnitViewmodel } from '@/modules/business-unit/viewmodels/business-unit-get-all.viewmodel';
import { BusinessUnitEntity } from '@/domains/business-unit/entities';
import { FindResult, UseRequestReturn } from '@/core/interfaces/findResult';
import { useFindAllMarketingProjectViewmodel } from '@/modules/marketing-project/viewmodels';
import { MarketingProjectEntity } from '@/domains/marketing-project/entities';
import { FindByCriteriaPayload } from '@/domains/marketing-project/ports/payloads';

const PageBusinessParamsContext = createContext<TContextValues>({} as any);

export const usePageBusinessParamsContext = () => {
  const values = React.useContext(PageBusinessParamsContext);
  if (!values) {
    throw new Error('usePageBusinessParamsContext must be used within a BusinessParamsProvider');
  }
  return values;
};

const Provider = ({ children, ...props }: React.PropsWithChildren<TContextValues>) => {
  return (
    <PageBusinessParamsContext.Provider value={props}>
      {children}
    </PageBusinessParamsContext.Provider>
  );
};

const MemoProvider = React.memo(Provider);

// MAIN
export const PageBusinessParamsProvider = ({ children }: React.PropsWithChildren) => {
  const [pageUrlState, setPageUrlState] = useUrlState<TPageUrlState>({
    id: undefined,
    actions: undefined,
  });

  const methodFilterPagination = useForm<FindPaginationBusinessParametersPayload>({
    defaultValues: { page: 1, limit: 20 },
  });
  const filterValues = methodFilterPagination.watch();

  const FindPaginateReq = useFindPaginationBusinessParametersViewmodel();

  useEffect(() => {
    FindPaginateReq.run(filterValues);
  }, [JSON.stringify(filterValues)]);

  const FindAllBusinessUnitReq = useFindAllBusinessUnitViewmodel();
  const FindAllMarketingProjectReq = useFindAllMarketingProjectViewmodel();

  useEffect(() => {
    FindAllBusinessUnitReq.run();
    FindAllMarketingProjectReq.run({});
  }, []);

  const data: TContextValues = {
    pageUrlState,
    setPageUrlState,
    businessParamsPagination: pick(FindPaginateReq, [
      'data',
      'loading',
      'runAsync',
      'refresh',
      'refreshAsync',
    ]),
    businessUnitList: pick(FindAllBusinessUnitReq, ['data', 'loading', 'runAsync', 'refresh']),
    marketingProjectList: pick(FindAllMarketingProjectReq, [
      'data',
      'loading',
      'runAsync',
      'refresh',
    ]),
    methodFilterPagination,
  };

  return <MemoProvider {...data}>{children}</MemoProvider>;
};

export type TPageUrlState = {
  actions?: string;
  id?: string;
};

interface TContextValues {
  businessParamsPagination: Pick<
    UseRequestReturn<
      FindResult<BusinessParametersEntity>,
      [FindPaginationBusinessParametersPayload]
    >,
    'data' | 'loading' | 'runAsync' | 'refresh' | 'refreshAsync'
  >;
  methodFilterPagination: UseFormReturn<FindPaginationBusinessParametersPayload>;
  businessUnitList: Pick<
    UseRequestReturn<BusinessUnitEntity[], []>,
    'data' | 'loading' | 'runAsync' | 'refresh'
  >;
  marketingProjectList: Pick<
    UseRequestReturn<MarketingProjectEntity[], [FindByCriteriaPayload]>,
    'data' | 'loading' | 'runAsync' | 'refresh'
  >;
  pageUrlState: TPageUrlState;
  setPageUrlState: React.Dispatch<React.SetStateAction<TPageUrlState>>;
}
