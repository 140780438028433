import { cn } from '@/common';
import { Typography } from 'antd';
import React from 'react';

type TFormLabelProps = {
  label?: React.ReactNode;
  required?: boolean;
  className?: string;
  extraRight?: React.ReactNode;
};

const FormLabel = ({ label, required, className, extraRight }: TFormLabelProps) => {
  if (!label) return <></>;
  return (
    <Typography.Text className={cn('label-container mb-1', className)}>
      {label} {required ? <span className='text-[#F5222D]'>*</span> : ''}
      {extraRight}
    </Typography.Text>
  );
};

export default FormLabel;
