import { Modal, notification } from 'antd';
import { Typography } from 'antd/lib';
import React, { useId } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from '@/yupGlobal';
import { useProductLineContext } from '../../context';
import { useCreateProductLinesViewModel } from '../../viewmodels/create';
import ProductLineForm from './ProductLineForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';

type TProductLineCreateProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const Schema = Yup.object({
  isActive: Yup.boolean().default(true),
  name: Yup.string().required(i18next.t('common.required_field')),
  productCategoryId: Yup.string().required(i18next.t('common.required_field')),
  productTopicIds: Yup.array(Yup.string().required())
    .min(1, i18next.t('common.required_field'))
    .default([]),
});

type TSchema = Yup.InferType<typeof Schema>;

const ProductLineCreate = ({ open, onClose }: TProductLineCreateProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const { productLinesFindPaginationAction } = useProductLineContext();

  const methodForm = useForm<TSchema>({
    mode: 'onChange',
    resolver: yupResolver(Schema),
    defaultValues: {
      isActive: true,
      name: '',
      productTopicIds: [],
      productCategoryId: '',
    },
  });

  const CreateProductLineReq = useCreateProductLinesViewModel();

  const handleSubmitForm = methodForm.handleSubmit(async (formData) => {
    try {
      const { name, productCategoryId, productTopicIds } = formData;
      await CreateProductLineReq.runAsync({
        name,
        productCategoryId,
        productTopicIds,
      });

      productLinesFindPaginationAction.refresh();

      onClose?.();
      methodForm.reset();
      notification.success({ message: 'Created new product line' });
    } catch (error) {
      console.log('error:', error);
      notification.error({ message: getErrorMessage(error) });
    }
  });

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={
        <Typography.Text type='secondary' style={{ margin: 0 }}>
          {t('common.productLines')}
        </Typography.Text>
      }
      bodyStyle={{ padding: '0 0' }}
      width={648}
      cancelButtonProps={{ onClick: onClose }}
      okButtonProps={{ form: 'ProductLineCreate', htmlType: 'submit' }}
    >
      <ProductLineForm
        methodForm={methodForm}
        id={'ProductLineCreate'}
        onSubmit={handleSubmitForm}
        hiddenStatus
      />
    </Modal>
  );
};

export default ProductLineCreate;
