import React from 'react';
import { cn } from '@/common';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Pagination } from 'antd';
import { useId, useLayoutEffect } from 'react';
import useBusinessParametersColumns from '../hooks/useBusinessParametersColumns';
import { usePageBusinessParamsContext } from '../context';
import { useTranslation } from 'react-i18next';
import StyledTable from '@/components/Table/StyledTable';
import { BusinessParametersEntity } from '@/domains/business-parameters/entities';
import BusinessParametersCreateModal from './BusinessParametersCreateModal';
import BusinessParametersUpdateModal from './BusinessParametersUpdateModal';

type TBusinessParametersListTableProps = { className?: string };

const BusinessParametersListTable = ({ className }: TBusinessParametersListTableProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const { businessParamsPagination, methodFilterPagination, pageUrlState, setPageUrlState } =
    usePageBusinessParamsContext();

  const filterValues = methodFilterPagination.watch();
  const listData = businessParamsPagination?.data?.data || [];

  const Columns = useBusinessParametersColumns();

  const handleCloseModal = () => {
    setPageUrlState({
      id: undefined,
      actions: undefined,
    });
  };

  return (
    <div className={cn('flex flex-col pt-0 flex-[1_1_auto] min-h-0', className)}>
      <div className='flex justify-between items-center px-4 py-4 gap-4'>
        <div className='flex items-center'>
          <span className='text-base font-[600] opacity-60'>{t('Business Parameters')}</span>
        </div>

        <div className='flex items-center gap-4'>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => {
              businessParamsPagination.refresh();
            }}
          />
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              setPageUrlState((p) => ({ ...p, actions: 'Create', id: undefined }));
            }}
          >
            {t('Create')}
          </Button>
        </div>
      </div>

      <StyledTable<BusinessParametersEntity>
        dataSource={listData || []}
        columns={Columns}
        className='sticky-scroll min-h-0 flex-[1_1_auto] [&_.ant-table-thead_th]:h-14 [&_.ant-table-cell-row-hover>*]:visible'
        size='middle'
        rowKey={(row) => String(row?.id) + '-' + uid}
        scroll={{ x: '100%' }}
        sticky={{ offsetHeader: 0.01 }}
        bordered
        loading={businessParamsPagination.loading}
        showSorterTooltip={false}
        pagination={false}
      />

      <Pagination
        total={listData.length}
        showTotal={(total) => `Total: ${total > 999 ? '≥ 1000' : total}`}
        defaultPageSize={20}
        defaultCurrent={1}
        showSizeChanger
        current={filterValues?.page}
        pageSize={filterValues?.limit}
        onChange={(page, pageSize) => {
          methodFilterPagination?.setValue('page', page);
          methodFilterPagination?.setValue('limit', pageSize);
        }}
        pageSizeOptions={['5', '10', '20']}
        className='py-4 px-4 !mx-0 !mb-0 !mt-auto flex items-center sticky bottom-0 bg-white z-50 border-0 border-t border-solid border-t-gray-200 [&>.ant-pagination-prev]:ml-auto [&>.ant-pagination-total-text]:ml-4'
      />

      <BusinessParametersCreateModal
        open={pageUrlState?.actions === 'Create'}
        onClose={() => {
          handleCloseModal();
        }}
      />

      {!!pageUrlState?.id && (
        <BusinessParametersUpdateModal
          open={pageUrlState?.actions === 'Update' && !!pageUrlState?.id}
          onClose={() => {
            handleCloseModal();
          }}
          itemId={pageUrlState?.id}
        />
      )}

      {/* 

      <ContractReminderEditForm
        open={pageUrlState?.actions === 'Edit' && !!pageUrlState?.id}
        itemId={pageUrlState?.id}
        onClose={() => {
          handleCloseModal();
        }}
      /> */}
    </div>
  );
};

export default BusinessParametersListTable;
