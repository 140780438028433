import React from 'react';
import { PageBusinessParamsProvider } from '../context';
import BusinessParametersListTable from '../components/BusinessParametersListTable';

type TBusinessParametersListPageProps = { className?: string };

const InternalBusinessParametersListPage = ({ className }: TBusinessParametersListPageProps) => {
  return (
    <div className='bg-white min-h-full flex flex-col'>
      <BusinessParametersListTable />
    </div>
  );
};

//
const BusinessParametersListPage = () => (
  <PageBusinessParamsProvider>
    <InternalBusinessParametersListPage />
  </PageBusinessParamsProvider>
);
export default BusinessParametersListPage;
