import { cn } from '@/common';
import FormLabel from '@/components/Form/Helpers/FormLabel';
import { Checkbox, Select } from 'antd';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePageBusinessParamsContext } from '../context';
import FormMessage from '@/components/Form/Helpers/FormMessage';

type TBPProjectScopeProps = {
  methodForm: UseFormReturn<any>;
  name: string;
  className?: string;
};

const BPProjectScope = ({ methodForm, name, className }: TBPProjectScopeProps) => {
  const { t } = useTranslation();
  const { businessUnitList, marketingProjectList } = usePageBusinessParamsContext();

  const marketingProjectListData = marketingProjectList?.data || [];
  const marketingProjectListMapped = marketingProjectListData?.map((item) => ({
    value: item.id,
    label: [item?.shortName, item?.name].filter(Boolean).join(' - '),
  }));

  return (
    <Controller
      control={methodForm.control}
      name={name}
      render={({ field, fieldState }) => {
        const selectedProjectIds = field.value?.projectIds;
        const isApplyAllProject = !!field.value?.isApplyAllProject;
        return (
          <div className={cn('flex flex-col mb-4', className)}>
            <FormLabel required label={t('Project applied')} />
            <Select
              disabled={isApplyAllProject}
              options={marketingProjectListMapped}
              placeholder={isApplyAllProject ? t('Applied all') : t('Select project(s)')}
              showSearch
              mode='multiple'
              status={fieldState.error?.message ? 'error' : undefined}
              className='[&_.ant-select-selection-item]:text-gray-800'
              value={isApplyAllProject ? undefined : selectedProjectIds}
              onChange={(value) => {
                field.onChange({ projectIds: value || [], isApplyAllProject: false });
              }}
            />
            <Checkbox
              className='mt-1'
              checked={!isApplyAllProject}
              onChange={(e) => {
                const checked = e.target.checked;
                field.onChange({
                  projectIds: selectedProjectIds,
                  isApplyAllProject: !checked,
                });
              }}
            >
              {t('Apply for each project')}
            </Checkbox>
            <FormMessage content={fieldState.error?.message} />
          </div>
        );
      }}
    />
  );
};

export default BPProjectScope;
