export enum BusinessParameterStatusEnum {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export enum BusinessParameterTypeEnum {
  CR16 = 'CR16',
  AOV = 'AOV',
  REVENUE = 'REVENUE',
}

export enum EntryTypeEnum {
  SINGLE_VALUE = 'SINGLE_VALUE',
  PERCENTAGE_DISTRIBUTION = 'PERCENTAGE_DISTRIBUTION',
}

export enum MeasurementUnitEnum {
  VND = 'VND',
  PERCENT = 'PERCENT',
}

export interface BusinessParameterTimeUnit {
  month?: number;
  year: number;
}

export interface ProjectDistribution {
  projectId: string;
  value: number;
}

//

export interface BusinessParametersEntity {
  id: string;
  name: string;
  status: BusinessParameterStatusEnum;
  type: BusinessParameterTypeEnum;
  entryType: EntryTypeEnum;
  measurementUnit: MeasurementUnitEnum;
  organizationScope?: { businessUnitIds: string[]; isApplyAllBusinessUnit?: string };
  timeScope: { appliedTime: BusinessParameterTimeUnit[] };
  projectScope?: { projectIds: string[]; isApplyAllProject?: string };
  singleConfiguration?: { value: number };
  percentageDistributionConfiguration?: { percentageDistributionByProject: ProjectDistribution[] };
}

//

export const BPTypes = [
  { value: BusinessParameterTypeEnum.CR16, label: 'Estimated CR16' },
  {
    value: BusinessParameterTypeEnum.AOV,
    label: 'Estimated AOV',
  },
  {
    value: BusinessParameterTypeEnum.REVENUE,
    label: 'Estimated REVENUE',
  },
];

export const BPMeasurementUnitList = [
  {
    value: MeasurementUnitEnum.VND,
    label: 'VND',
  },
  {
    value: MeasurementUnitEnum.PERCENT,
    label: '%',
  },
];

export const BPStatuses = [
  {
    value: BusinessParameterStatusEnum.ACTIVE,
    label: 'Active',
  },
  {
    value: BusinessParameterStatusEnum.INACTIVE,
    label: 'Inactive',
  },
];
