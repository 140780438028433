import { gql } from '@apollo/client';

const BusinessParametersData = `
  id
  name
  status
  type
  entryType
  measurementUnit
  organizationScope {
    businessUnitIds
    isApplyAllBusinessUnit
  }
  timeScope {
    appliedTime {
      year
      month
    }
  }
  projectScope {
    projectIds
    isApplyAllProject
  }
  singleConfiguration {
    value
  }
  percentageDistributionConfiguration {
    percentageDistributionByProject {
      projectId
      value
    }
  }
`;

// GraphQL Queries
export const FIND_ONE_BUSINESS_PARAMETER = gql`
  query BusinessParameter_findOneBusinessParameter($criteria: FindBusinessParameterInput) {
    BusinessParameter_findOneBusinessParameter(criteria: $criteria) {
      ${BusinessParametersData}
    }
  }
`;

export const FIND_PAGINATED_BUSINESS_PARAMETERS = gql`
  query BusinessParameter_findPaginatedBusinessParameters($payload: FindPaginatedBusinessParameterInput) {
    BusinessParameter_findPaginatedBusinessParameters(payload: $payload) {
      data {  
        ${BusinessParametersData}
      }
      total
      page
      limit
    }
  }
`;

// GraphQL Mutations
export const CREATE_BUSINESS_PARAMETER = gql`
  mutation BusinessParameter_createBusinessParameter($payload: CreateBusinessParameterInput!) {
    BusinessParameter_createBusinessParameter(payload: $payload) {
      ${BusinessParametersData}
    }
  }
`;

export const UPDATE_BUSINESS_PARAMETER = gql`
  mutation BusinessParameter_updateBusinessParameter($payload: UpdateBusinessParameterInput!) {
    BusinessParameter_updateBusinessParameter(payload: $payload) {
      ${BusinessParametersData}
    }
  }
`;
